<template>
    <div id="apps">
        <div class="hxjilv clear">
            <div class="top clear">
                <label>金额：￥{{total}}</label>
                <div class="select_item" @click="selectShow = true">
                    {{this.currentDate != null ? this.currentDate.getFullYear() + '年' + parseInt(this.currentDate.getMonth()+ 1) + '月' : '全部'}}
                </div>
            </div>

            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" direction="up">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <ul class="clear" v-for="item in list">
                        <li>
                            <h5>订单号：{{item.orderNo}}</h5>
                            <p>{{item.productName}} *{{item.num}}</p>
                        </li>
                        <li>
                            <label>订单金额：￥{{item.totalAmount}}</label>
                            <h5>姓名：{{item.buyerName}}</h5>
                            <h5>联系电话：{{item.buyerPhone}}</h5>
                            <h5>下单时间：{{item.orderCreateTime}}</h5>
                            <h5>核销时间：{{item.createTime}}</h5>
                        </li>
                    </ul>
                </van-list>
            </van-pull-refresh>
        </div>

        <van-popup v-model="selectShow" position="bottom">
            <van-datetime-picker
                    v-model="currentDate"
                    type="year-month"
                    title="选择年月"
                    :min-date="minDate"
                    :formatter="formatter"
                    @confirm="confirmDate"
                    @cancel="cancelDate"
            />
        </van-popup>
    </div>
</template>

<script>
  import {hexiaoRecords} from "@/api/order";

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        selectShow: false,
        loading: false,
        finished: false,
        refreshing: false,
        pageNum: 1,
        pages: 1,
        list: [],
        total: 0,
        currentDate: null,
        minDate: new Date(2020, 0, 1),
      }
    },
    methods: {
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }
        return val;
      },
      confirmDate() {
        this.refreshing = true;
        this.onRefresh();
        this.selectShow = false;
      },
      cancelDate() {
        this.currentDate = null;
        this.selectShow = false;
        this.refreshing = true;
        this.onRefresh();
      },
      loadList () {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return
        }
        this.loading = true;
        const params = {
          pageNum: this.pageNum,
        };
        if (this.currentDate) {
          params.yearMonth = this.currentDate.getFullYear() + '-' + parseInt(this.currentDate.getMonth() + 1)
        }
        hexiaoRecords(params).then(data => {
          if (data.success == true) {
            console.log(data.data.page)
            this.pageNum = data.data.page.current + 1;
            this.pages = data.data.page.pages;
            this.list.push(...data.data.page.records);
            this.total = data.data.totalAmountSum;
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        }).finally(() => {
          this.loading = false
        }).catch(() => {
          this.finished = true;
        })
      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadList()
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
    }
  }
</script>

<style>
    .select_item {
        display: inline-block;
        color: #333;
        padding-right: 15px;
        float: right;
        font-size: 12px;
    }

    .top div:after {
        top: 4px;
        border-color: #fff #333 #333 #fff;
        transform: rotate(45deg);
    }

    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .hxjilv {
        width: 100%;
        /*height: 100vh;*/
        background-color: rgba(249, 249, 249, 100);;
    }

    .hxjilv ul li h5 {
        font-weight: 200;
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        line-height: 18px;
        font-family: '方正准圆-标准;'
    }

    .hxjilv label {
        color: rgba(251, 6, 6, 100);
        font-size: 12px;
        font-family: '方正准圆-标准;'
    }

    .hxjilv .top {
        width: 94%;
        margin: 3% auto 1% auto;
    }

    .hxjilv .top select {
        width: 120px;
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        border: 1px solid rgba(187, 187, 187, 100);
        line-height: 25px;
        float: right;
    }

    .hxjilv ul {
        width: 94%;
        margin: 0 auto;
        background: #fff;
        padding: 2% 3%;
    }

    .hxjilv ul li {
        width: 100%;
        clear: both;
        overflow: hidden;
        border-bottom: 1px solid rgba(232, 232, 232, 100);
        padding-bottom: 3%;
        margin-top: 2%;
    }

    .hxjilv ul li p {
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        font-family: '方正准圆-标准;'
    }

    .hxjilv ul li:last-child {
        border-bottom: none;
    }
</style>
